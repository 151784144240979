/* eslint-disable no-unused-vars */
// import { Loader } from 'components/Loader';
import React, { lazy, Suspense, useEffect } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
	useLocation
} from 'react-router-dom';
import logo from 'assets/images/logo-white.svg';
import { ToastContainer } from 'react-toastify';

const Home = lazy(() => import('pages/Home'));
const Contact = lazy(() => import('pages/Contact'));
const About = lazy(() => import('pages/About'));
const Features = lazy(() => import('pages/Features'));
const Pricing = lazy(() => import('pages/Pricing'));
const NotFound = lazy(() => import('pages/404'));
const GetStarted = lazy(() => import('pages/GetStarted'));
const Faqs = lazy(() => import('pages/Faqs'));
const Privacy = lazy(() => import('pages/Privacy'));
const Terms = lazy(() => import('pages/Terms'));

const routes = [
	{ path: '/', Component: Home },
	{ path: '/contact-us', Component: Contact },
	// { path: '/about', Component: About },
	{ path: '/features', Component: Features },
	{ path: '/pricing', Component: Pricing },
	{ path: '/get-started', Component: GetStarted },
	{ path: '/404', Component: NotFound },
	{ path: '/faqs', Component: Faqs },
	{ path: '/privacy', Component: Privacy },
	{ path: '/terms', Component: Terms }
];

const ScrollToTop = ({ children }) => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return children;
};

const Fallback = () => {
	return (
		<div className="loader__fallback">
			<img src={logo} alt={'Logo'} />
		</div>
	);
};

const App = () => {
	return (
		<Router>
			<ToastContainer theme="colored" className={'z-index-lg'} />
			<Switch>
				{routes.map(({ path, Component }, i) => {
					return (
						<Route
							exact
							key={i}
							path={path}
							render={(routeProps) => (
								<Suspense fallback={<Fallback />}>
									<ScrollToTop>
										<Component
											{...routeProps}
											show={routeProps.match !== null}
										/>
									</ScrollToTop>
								</Suspense>
							)}
						/>
					);
				})}
				<Redirect to="/404" />
			</Switch>
		</Router>
	);
};

export default App;
